import React, { useState } from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { v4 } from "uuid"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PrimaryButton from "../components/Button/PrimaryButton"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import SubCategoryProductSection from "../sections/Product/SubCategoryProductSection"
import {
  Section,
  SectionPageTitle,
  SectionDescription,
  BreadCrumb,
  MainPanel,
  LeftPannel,
  RightPannel,
  MarkdownContent,
} from "../components/Section"
import CustomQuote from "../components/CustomQuote"
import BreakpointUp from "../components/Media/BreakpointUp"
import QuotePopup from "../components/QuotePopup"

const TopHeaderSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  ${BreakpointUp.lg`
    margin:0 -30px;
  `}
  ${BreakpointUp.xl`
    margin:0 -30px;
  `}
`
const TopHeaderLeft = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`
    flex: 0 0 75%;
    max-width: 75%;
    padding-right: 30px;
    padding-left: 30px;
  `}
  .gatsby-image-wrapper {
    margin-bottom: 20px;
  }
  h1 {
    background: #fff;
    bottom: -30px;
    display: inline-block;
    max-width: 710px;
    width: 100%;
    margin-bottom: 0;

    font-size: 34px;
    line-height: 40px;
    @media (min-width: 992px) {
      font-size: 42px;
      line-height: 52px;
    }


  }
`
const TopHeaderRight = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  ${BreakpointUp.lg`
    flex: 0 0 25%;
    max-width: 25%;
    padding-right: 30px;
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  `}
`

const Features = styled.div`
  text-align: left;
  ${BreakpointUp.lg`
      margin: 60px 0 30px 0;
  `}
`
const FeaturesLabel = styled.div`
  color: #999;
  font-weight: 700;
  margin-bottom: 15px;
`
const FeaturesList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`
const FeaturesItem = styled.li`
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: -20%;
    top: 12px;
    background-image: linear-gradient(
      to right,
      rgb(252, 0, 2) 50%,
      rgb(255 255 255 / 0) 0%
    );
    background-position: 0px 0px;
    background-size: 12px 2px;
    background-repeat: repeat-x;
    width: 17%;
    height: 2px;
  }
  + li {
    margin-top: 20px;
  }
`
const MainPanels = styled(MainPanel)`
  @media (max-width: 991px) {
    flex-direction: column-reverse;    
  }
`

const SubCategoryLanding = ({ data, location }) => {
  const [isVisibleQuote, setQuoteVisible] = useState(false)
  if (typeof window !== `undefined`) {
    if (isVisibleQuote === true) {
      document.body.classList.add("modal-open")
    } else {
      document.body.classList.remove("modal-open")
    }
  }
  const pageData = data.contentfulSubCategoryLanding
  const productsData = []

  data.allContentfulProduct.edges.forEach(function (item) {
    var flag = 0
    item.node.productSubCategories.forEach(function (node) {
      if (node.name === pageData.subCategory.name) {
        flag = flag + 1
      }
    })
    if (flag > 0) {
      productsData.push(item)
    }
  })
  return (
    <Layout location={location}>
      <Seo title={pageData.metaTitle} description={pageData.metaDescription.metaDescription} />
      <BreadCrumb>
        <div className="container">
          <Link to="/">Home / </Link>
          <Link to={pageData.category.url}>{pageData.category.name} / </Link>
          <span>{pageData.subCategory.name}</span>
        </div>
      </BreadCrumb>
      <Section pt="215px" pb="90px" xpt="90px" xpb="60px" bgColor="#fff">
        <div className="container">
          <TopHeaderSection>
            <TopHeaderLeft>
              <SectionPageTitle textAlign="left">
                {pageData.heroTitle}
              </SectionPageTitle>
              <GatsbyImage
                image={pageData.heroImage.gatsbyImageData}
                layout="fixed"
                alt={pageData.heroImage.title}
              />
              <SectionDescription textAlign="left">
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageData.heroDescription.childMarkdownRemark.html,
                  }}
                />
              </SectionDescription>
            </TopHeaderLeft>
            <TopHeaderRight>
              <Features>
                <FeaturesLabel>Features:</FeaturesLabel>
                <FeaturesList>
                  {pageData.heroFeatures.map(item => (
                    <FeaturesItem key={v4()}>{item.content}</FeaturesItem>
                  ))}
                </FeaturesList>
              </Features>

              <a href="tel:8666817846" aria-label="(866) 681-7846"><PrimaryButton text="Call Now to get Started" /></a>
            </TopHeaderRight>
          </TopHeaderSection>
        </div>
      </Section>
      <SubCategoryProductSection
        sectionShadow={pageData.productListLabel}
        sectionTitle={pageData.productListTitle}
        data={productsData}
        category={pageData.category.name}
        buildingType={pageData.subCategory.name}
        openQuoteModal={() => setQuoteVisible(true)}
      />
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#fff">
        <div className="container">
          <MainPanels>
            <LeftPannel>
              <MarkdownContent>
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageData.content.childMarkdownRemark.html,
                  }}
                />
              </MarkdownContent>
            </LeftPannel>
            <RightPannel>
              <div className="isSticky">
                <CustomQuote location={location} />
              </div>
            </RightPannel>
          </MainPanels>
        </div>
      </Section>
      <QuotePopup
        states={data.allContentfulState.edges}
        isVisible={isVisibleQuote}
        onClose={() => setQuoteVisible(false)}
        location={location}
      />
    </Layout>
  )
}

export default SubCategoryLanding

export const pageQuery = graphql`
  query ProductSubCategoryLandingQuery($id: String!) {
    contentfulSubCategoryLanding(id: { eq: $id }) {
      metaTitle
      metaDescription {
        metaDescription
      }
      heroTitle
      heroFeatures {
        content
      }
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        title
        gatsbyImageData(height: 565)
      }
      category {
        name
        url
      }
      subCategory {
        name
        url
      }
      productListLabel
      productListTitle
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulProduct {
      edges {
        node {
          metaTitle
          metaDescription
          productSku
          productName
          url
          productDescription {
            childMarkdownRemark {
              html
            }
          }
          featuredProduct
          productCategory {
            name
            url
          }
          productSubCategories {
            name
            url
          }
          productRoofStyle {
            name
          }
          productApplications {
            name
          }
          productImages {
            title
            gatsbyImageData
          }
          width
          height
          length
          price
          applicableForFinancing
          applicableForFreeDelivery
          applicableForRto
          otherDetails {
            childMarkdownRemark {
              html
            }
          }
          installationStates {
            name
            url
          }
        }
      }
    }
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
        }
      }
    }
  }
`